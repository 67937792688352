import React, { useState, useEffect, useCallback } from 'react';
import { format } from 'date-fns';

import Container from '../../components/elements/Container';
import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';
import ModalFrame from './ModalFrame';
import Modal from '../../components/Modal';

import {
  getProposta,
  verificarStatusProposta,
} from '../../webReferences/propostaAPI';
import {
  listarMovimentos,
  obterURLBoleto,
} from '../../webReferences/financeiroAPI';

import { currencyFormat } from '../../helpers/Utils';
import { useParams } from 'react-router-dom';
import CardResumo from './CardResumo';

import './styles.scss';

export default function PropostaIndividual() {
  const params = useParams();
  const propostaId = params?.propostaId;
  const [proposta, setProposta] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContratoVisible, setModalContratoVisible] = useState(false);
  const [modalBoletoVisible, setModalBoletoVisible] = useState(false);
  const [urlBoleto, setURLBoleto] = useState(null);
  const [listaMovimentos, setListaMovimentos] = useState(null);
  const [totalParcelasPagas, setTotalParcelasPagas] = useState(0);
  const [totalParcelasVencidas, setTotalParcelasVencidas] = useState(0);
  const [totalParcelasVencer, setTotalParcelasVencer] = useState(0);

  const modalToggle = () => setModalVisible(!modalVisible);
  const modalContratoToggle = () => setModalContratoVisible(!modalContratoVisible);
  const modalBoletoToggle = () => setModalBoletoVisible(!modalBoletoVisible);

  const loadProposta = useCallback(async () => {
    setLoading(true);
    //console.log("propostaId", propostaId)
    const data = await getProposta(propostaId);
    if (data) {
      //console.log(data)
      setProposta(data || null);
    }

    if (data.propostaFinanceiro != null) {

      await listarMovimentos(data.propostaFinanceiro.numeroCCB).
        then((data) => {
          setListaMovimentos(data || null);
          var parcelasPagas = 0
          var parcelasVencidas = 0
          var parcelasVencer = 0;
          if (data.data != undefined) {
            for (var i = 0; i < data.data.movimentos.length; i++) {
              if (data.data.movimentos[i].detalhes.cStatus === "A VENCER") {
                parcelasVencer += data.data.movimentos[i].detalhes.nValorTitulo;
              }
              else if (data.data.movimentos[i].detalhes.cStatus === "ATRASADO") {
                parcelasVencidas += data.data.movimentos[i].detalhes.nValorTitulo;
              }
              else {
                parcelasPagas += data.data.movimentos[i].detalhes.nValorTitulo;
              }
            }
          }
          setTotalParcelasPagas(parcelasPagas || 0);
          setTotalParcelasVencidas(parcelasVencidas || 0);
          setTotalParcelasVencer(parcelasVencer || 0);
        });
    }

    setLoading(false);
  }, [propostaId]);

  async function obterBoleto(nCodTitulo) {
    setLoading(true);
    const data = await obterURLBoleto(nCodTitulo);

    setLoading(false);
    setURLBoleto(data.data.cLinkBoleto);
    setModalBoletoVisible(true);

    //window.open(data.data.cLinkBoleto);
  }

  useEffect(() => {
    if (propostaId) {
      loadProposta();
    }
  }, [propostaId, loadProposta]);

  const nomeCompleto = () =>
    proposta && `${proposta?.cliente?.nome} ${proposta?.cliente?.sobrenome}`;

  const endereco = () => {
    const enderecoCliente = proposta?.cliente?.endereco?.[0];
    const { logradouro, numero, bairro, complemento, cep, cidade, estado } =
      enderecoCliente;

    return (
      <>
        {logradouro} {numero}, {bairro} - {complemento}
        <br />
        {cep} - {cidade} - {estado}
      </>
    );
  };

  const getStatus = (status) => {
    switch (status) {
      case 'Aguardando crédito':
      case 'Em andamento':
      case 'Aguardando assinatura':
        return 'primary';
      case 'Pendente':
        return 'warning';
      case 'Assinada':
      case 'Crédito efetuado':
        return 'success';
      case 'Recusado':
      case 'Cancelado':
        return 'danger';
      default:
    }
  };

  const handleStatusProposta = async () => {
    await verificarStatusProposta(propostaId);
    setTimeout(() => loadProposta(), 5000);
  };

  function handleCopiarCodigo(codigo) {
    Modal({
      text: 'Código de barras copiado com sucesso!',
      icon: 'success',
      action: () => navigator.clipboard.writeText(codigo),
    });
  }

  function listarParcelas() {

    if (listaMovimentos === null) {

      return <></>;
    }
    try {
      const listItens = listaMovimentos.data.movimentos.map((item) => (

        <tr class="font-weight-bolder font-size-lg">
          <td class={item.detalhes.cStatus === "A VENCER" ?
            "border-top-0 pl-0 pl-md-5 pt-7 d-flex align-items-center text-primary" :
            item.detalhes.cStatus === "ATRASADO" ?
              "border-top-0 pl-0 pl-md-5 pt-7 d-flex align-items-center text-danger" :
              "border-top-0 pl-0 pl-md-5 pt-7 d-flex align-items-center text-success"}>
            <span class="navi-icon mr-2">
              <i class="fa fa-genderless text-success font-size-h2"></i>
            </span>
            {item.detalhes.cNumParcela}
          </td>
          <td class="text-center pt-7">{item.detalhes.dDtVenc}</td>
          <td class={item.detalhes.cStatus === "A VENCER" ?
            "pr-0 pt-7 font-size-h6 font-weight-boldest text-center text-primary" :
            item.detalhes.cStatus === "ATRASADO" ?
              "pr-0 pt-7 font-size-h6 font-weight-boldest text-center text-danger" :
              "pr-0 pt-7 font-size-h6 font-weight-boldest text-center text-success"}>
            {currencyFormat(item.detalhes.nValorTitulo)}
          </td>
          <td class={item.detalhes.cStatus === "A VENCER" ?
            "pr-0 pt-7 font-size-h6 font-weight-boldest text-center text-primary" :
            item.detalhes.cStatus === "ATRASADO" ?
              "pr-0 pt-7 font-size-h6 font-weight-boldest text-center text-danger" :
              "pr-0 pt-7 font-size-h6 font-weight-boldest text-center text-success"}>
            {item.detalhes.cStatus}
            {console.log(item.detalhes.cStatus)}
          </td>
          <td class="pr-0 pt-7 font-size-h6 font-weight-boldest text-right" colSpan="2">
            <div Style={'display: ' + (item.detalhes.cStatus === "RECEBIDO" ? 'none' : 'block')}>
              <div title="Copiar código de barras" onClick={() => { handleCopiarCodigo(item.detalhes.cCodigoBarras) }} className="btn btn-icon btn-outline-success btn-circle btn-sm mr-2">
                <i className="fas la-barcode"></i>
              </div>
              <div title="Obter boleto" className="btn btn-icon btn-outline-success btn-circle btn-sm mr-2">
                <i className="fas la-file-invoice" onClick={() => obterBoleto(item.detalhes.nCodTitulo)}></i>
              </div>
            </div>
          </td>
        </tr>
      ));

      if (listItens.length === 0) {
        Modal({
          text: 'Olá, seus boletos não foram gerados ainda, mas não se preocupe, avisaremos você por e-mail e mensagem de texto em seu celular.',
          icon: 'error',
          action: () => { },
        });
      }

      return listItens;
    }
    catch
    {

      return <></>;
    }
  }

  return (
    <Container>
      <Overlay active={loading}>
        <Card>
          {proposta && (
            <div className="card-body p-0">
              <div className="row justify-content-center py-8 px-8 py-md-15 px-md-0">
                <div className="col-md-9">
                  <div className="d-flex justify-content-between pb-10 pb-md-5 flex-column flex-md-row">
                    <h1 className="display-4 font-weight-boldest mb-10">
                      {nomeCompleto()}
                    </h1>
                    <div className="d-flex flex-column align-items-md-end px-0">
                      <span className="d-flex flex-column align-items-md-end opacity-70">
                        <span>{endereco()}</span>
                      </span>
                    </div>
                  </div>
                  <div className="border-bottom w-100"></div>
                  <div className="d-flex justify-content-between pt-6">
                    <div className="d-flex flex-column ">
                      <span className="font-weight-bolder mb-2">
                        Status da proposta:
                      </span>
                      <span
                        className={`label label-lg label-light-${getStatus(
                          proposta.statusProposta,
                        )} label-inline`}
                      >
                        {proposta.statusProposta}
                      </span>
                      {proposta.urlContratoAssinadoParaVisualizacao ?
                        <button
                          type="button"
                          className={`label label-lg label-light-Em andamento,)} label-inline`}
                          Style={'margin-top: 10px'}
                          onClick={() => setModalContratoVisible(true)}
                        >
                          Visualizar contrato
                        </button>
                        :
                        <></>}
                    </div>
                    <div className="d-flex flex-column ">
                      <span className="font-weight-bolder mb-2">
                        Data da solicitação
                      </span>
                      <span className="opacity-70">
                        {format(new Date(proposta?.dataProposta), 'dd/MM/yyyy')}
                      </span>
                    </div>
                    <div className="d-flex flex-column ">
                      <span className="font-weight-bolder mb-2">
                        Cod. da Proposta
                      </span>
                      <span className="opacity-70">
                        {proposta?.codigoProposta}
                      </span>
                    </div>
                    <div className="d-flex flex-column ">
                      <span className="font-weight-bolder mb-2">
                        Informações da Viagem
                      </span>
                      <span className="opacity-70">
                        {`Data Viagem: ${format(
                          new Date(proposta?.viagemTrecho[0]?.dataPartida),
                          'dd/MM/yyyy',
                        )}`}

                        <br />
                        {`Nº de Passageiros: ${proposta?.pessoa?.length} `}
                        <br />

                        {`Destino: ${proposta?.viagemTrecho[0]?.destino} `}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center mb-20 px-5">
                <div className="col-lg-4">
                  {proposta.statusProposta === 'Aguardando assinatura' && (
                    <button
                      type="button"
                      className="btn btn-success btn-lg btn-block font-weight-bold mt-3 blob green py-3"
                      onClick={() => modalToggle()}
                    >
                      Assinar contrato
                    </button>
                  )}
                </div>
              </div>

              <div className="row justify-content-center px-8 px-md-0">
                <div className="col-md-9">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="font-weight-bold text-muted text-uppercase">
                            Valor da Viagem
                          </th>
                          <th className="text-right font-weight-bold text-muted text-uppercase">
                            Valor da Parcela
                          </th>
                          <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">
                            Parcelamento
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="font-weight-boldest">
                          <td className="pt-7">
                            {proposta?.valorProposta &&
                              currencyFormat(proposta?.valorProposta)}
                          </td>
                          <td className="text-right pt-7">
                            {proposta?.valorParcela &&
                              currencyFormat(proposta?.valorParcela)}
                          </td>
                          <td className="text-right pr-0 pt-7 text-right">
                            {proposta?.quantidadeParcelas &&
                              `${proposta?.quantidadeParcelas}x`}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                <div className="col-md-9">
                  <h3>Resumo de pagamentos</h3>

                  <div className="row">
                    <div className="col-xl-4">
                      <CardResumo
                        title="Parcelas Pagas"
                        color="#3699FF"
                        value={totalParcelasPagas}
                      />
                    </div>
                    <div className="col-xl-4">
                      <CardResumo
                        title="Parcelas a Vencer"
                        color="#fcbb42"
                        value={totalParcelasVencer}
                      />
                    </div>
                    <div className="col-xl-4">
                      <CardResumo
                        title="Parcelas Vencidas"
                        color="#d10000"
                        value={totalParcelasVencidas}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                <div className="col-md-9">
                  <h1 className="display-4 font-weight-boldest mb-10">
                    Extrato Financeiro
                  </h1>

                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="pt-1 pb-9 text-left font-weight-bolder text-muted font-size-lg text-uppercase">
                            N° Parcela
                          </th>
                          <th class="pt-1 pb-9 text-center font-weight-bolder text-muted font-size-lg text-uppercase">
                            Data do Vencimento
                          </th>
                          <th class="pt-1 pb-9 text-center pr-0 font-weight-bolder text-muted font-size-lg text-uppercase">
                            Valor de Parcela
                          </th>
                          <th class="pt-1 pb-9 text-center font-weight-bolder text-muted font-size-lg text-uppercase">
                            Status
                          </th>
                          <th class="pt-1 pb-9 text-center pr-0 font-weight-bolder text-muted font-size-lg text-uppercase" colSpan="2">
                            Ação
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {listarParcelas()}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                <div className="col-md-9">
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-primary font-weight-bold"
                      onClick={() => window.print()}
                    >
                      Imprimir Proposta
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Card>
        <ModalFrame
          modalVisible={modalVisible}
          modalToggle={modalToggle}
          url={proposta?.urlAssinaturaContrato}
          title="Assinar contrato"
          action={() => handleStatusProposta()}
        />
        <ModalFrame
          modalVisible={modalContratoVisible}
          modalToggle={modalContratoToggle}
          url={proposta?.urlContratoAssinadoParaVisualizacao}
          title="Contrato"
          action={() => { }}
        />
        <ModalFrame
          modalVisible={modalBoletoVisible}
          modalToggle={modalBoletoToggle}
          url={urlBoleto}
          title="Boleto"
          action={() => { }}
        />
      </Overlay>
    </Container>
  );
}
