import axios from 'axios';
import Modal from '../components/Modal';
import { isBefore, isAfter, addMinutes, format } from 'date-fns';

import { getToken } from './authAPI';

const baseURL = 'https://api-lab.voepay.com.br/voepay/web';

const api = axios.create({
  baseURL,
});

const handleLogout = () => {
  localStorage.clear();
  window.location = '/login';
};

const handleModalLogout = () => {
  Modal({
    text: 'Obrigado pela sua visita!',
    icon: 'success',
    action: () => handleLogout(),
  });
};

api.interceptors.request.use(async (config) => {
  const dataExpiracaoSessao = localStorage.getItem('dataExpiracaoSessao');
  const dataAtual = new Date();
  const token = JSON.parse(localStorage.getItem('authTokenData'));

  if (token && config.url !== '/Usuario/Autenticar') {
    config.headers.Authorization = `Bearer ${token.accessToken}`;

    const dataAtualExpiracao = format(
      addMinutes(dataAtual, 2),
      'yyyy-MM-dd HH:mm',
    );
    const dataExpiracaoToken = format(
      new Date(token.expiringAt),
      'yyyy-MM-dd HH:mm',
    );

    if (
      dataAtualExpiracao === dataExpiracaoToken ||
      isAfter(new Date(dataAtualExpiracao), new Date(dataExpiracaoToken))
    ) {
      const authToken = await getToken();
      localStorage.setItem('authTokenData', JSON.stringify(authToken.data));
    }

    if (dataExpiracaoSessao) {
      if (isBefore(new Date(dataExpiracaoSessao), dataAtual)) {
        handleModalLogout();
        return Promise.reject();
      }
    }
  }

  return config;
});

export default api;
