import api from './api';

export const getConfig = async () => {
  try {
    const { data } = await api.get(
      'configuracoesgerais/GetConfiguracoesGerais',
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
