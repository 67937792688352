import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default function ModalFrame({
  modalVisible,
  modalToggle,
  url,
  title,
  action = () => {},
}) {
  return (
    <>
      <Modal
        isOpen={modalVisible}
        toggle={modalToggle}
        className="modal-dialog-centered modal-xl"
        onClosed={action}
      >
        <ModalHeader toggle={modalToggle}>{title}</ModalHeader>
        <ModalBody className="modal-content text-center">
          <iframe
            src={url}
            title={title}
            height="500px"
          />
        </ModalBody>
      </Modal>
    </>
  );
}
