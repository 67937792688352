import React from 'react';
import { ReactComponent as AbstractIcon } from '../../assets/svg/abstract.svg';

import { currencyFormat } from '../../helpers/Utils';

export default function CardResumo({ color, title, value }) {
  return (
    <div
      className="rounded d-flex align-items-center justify-content-between text-white max-w-350px position-relative ml-auto p-7 mt-10"
      style={{
        background: color,
      }}
    >
      <div className="position-absolute opacity-30 top-0 right-0">
        <span className="svg-icon svg-icon-2x svg-logo-white svg-icon-flip">
          <AbstractIcon />
        </span>
      </div>

      <div className="font-weight-boldest font-size-h5">{title}</div>
      <div className="text-right d-flex flex-column">
        <span className="font-weight-boldest font-size-h2 line-height-sm">
          {currencyFormat(value)}
        </span>
      </div>
    </div>
  );
}
