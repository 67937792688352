import React from 'react';
import './styles.scss';

export default function SpinnerLoading({ width, height }) {
  return (
    <div className="spinner-login">
      <div className="bounce1" style={{ width, height }}></div>
      <div className="bounce2" style={{ width, height }}></div>
      <div className="bounce3" style={{ width, height }}></div>
    </div>
  );
}
