import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

import './styles.scss';

export default function ModalSuccess({
  modalVisible,
  modalToggle,
  action = () => {},
}) {
  return (
    <Modal
      isOpen={modalVisible}
      toggle={modalToggle}
      className="modal-dialog-centered modal-sm"
    >
      <ModalBody className="modal-content text-center">
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        />

        <p>Mensagem enviada com sucesso!</p>

        <button
          onClick={() => action()}
          className="btn btn-primary rounded-pill btn-login w-100 mb-2"
          alt="Fechar"
        >
          Fechar
        </button>
      </ModalBody>
    </Modal>
  );
}
