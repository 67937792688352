import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Login from '../pages/Login';
import Home from '../pages/Home';
import MinhasPropostas from '../pages/MinhasPropostas';
import Proposta from '../pages/Proposta';
import Perfil from '../pages/Perfil';
import Contato from '../pages/Login/contato';

import PrivateRoute from './privateRoute';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/contato" component={Contato} />
      <PrivateRoute exact path="/home" component={Home} />
      <PrivateRoute
        exact
        path="/minhas-propostas"
        component={MinhasPropostas}
      />
      <PrivateRoute exact path="/perfil" component={Perfil} />
      <PrivateRoute exact path="/proposta/:propostaId" component={Proposta} />
    </Switch>
  );
}
