const initialValues = {
  nomeUsuario: '',
  email: '',
  confirmarEmail: '',
  celular: '',
  telefoneFixo: '',
  telefoneComercial: '',
  endereco: {
    cep: '',
    bairro: '',
    logradouro: '',
    estado: '',
    cidade: '',
    numero: '',
    complemento: '',
  },
  senha: '',
  confirmarSenha: '',
};

export default initialValues;
