import React, { useContext, useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Context } from '../context/AuthContext';

import NavbarMenu from '../components/elements/NavbarMenu';
import Footer from '../components/elements/Footer';

export default function PrivateRoute({ isPrivate, ...rest }) {
  const { loading, authenticated } = useContext(Context);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    function loadStyles() {
      return new Promise((resolve) => {
        resolve(
          import('../assets/css/style.bundle.css'),
          import('../assets/plugins/global/plugins.bundle.css'),
        );
      });
    }

    loadStyles().then(() => {
      setShowComponent(true);
    });
  }, []);

  if (loading) {
    return <div />;
  }

  if (!authenticated) {
    return <Redirect to="/login" />;
  }

  return (
    showComponent && (
      <>
        <NavbarMenu />
        <Route {...rest} />
        <Footer />
      </>
    )
  );
}
