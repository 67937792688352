import api from './api';

export const getPropostas = async (usuarioId) => {
  try {
    const { data } = await api.get(
      `/proposta/ListPropostas?usuarioId=${usuarioId}`,
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getProposta = async (propostaId) => {
  try {
    const { data } = await api.get(
      `/proposta/GetProposta?propostaId=${propostaId}`,
    );
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const verificarStatusProposta = async (propostaId) => {
  try {
    const { data } = await api.get(
      `/proposta/verificarStatusProposta?propostaId=${propostaId}`,
    );
    return data.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
