/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';

import Container from '../../components/elements/Container';
import Card from '../../components/elements/Card';
import Overlay from '../../components/Overlay';

import { currencyFormat } from '../../helpers/Utils';

import { getPropostas } from '../../webReferences/propostaAPI';

import { format } from 'date-fns';

import { Link } from 'react-router-dom';

export default function MinhasPropostas() {
  const userData = JSON.parse(localStorage.getItem('userData'));
  const { usuarioId } = userData.data;
  const [listPropostas, setListPropostas] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadPropostas() {
      setLoading(true);
      const ret = await getPropostas(usuarioId);
      setLoading(false);

      setListPropostas(ret?.data || []);
    }

    loadPropostas();
  }, [usuarioId]);

  function getStatus(status) {
    switch (status) {
      case 'Aguardando crédito':
      case 'Em andamento':
      case 'Aguardando assinatura':
        return 'primary';
      case 'Pendente':
        return 'warning';
      case 'Assinado':
      case 'Crédito efetuado':
        return 'success';
      case 'Recusado':
      case 'Cancelado':
        return 'danger';
      default:
    }
  }

  function ListPropostas() {
    const listItens = listPropostas?.map((item) => (
      <tr key={item.propostaId}>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {format(new Date(item.dataProposta), 'dd/MM/yyyy')}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {currencyFormat(item.valorProposta)}
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {item.quantidadeParcelas}x
          </span>
        </td>
        <td>
          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
            {currencyFormat(item.valorParcela)}
          </span>
        </td>
        <td>
          <span
            className={`label label-lg label-light-${getStatus(
              item.statusProposta,
            )} label-inline`}
          >
            {item.statusProposta}
          </span>
        </td>
        <td className="pr-0 text-right">
          <Link
            to={`/proposta/${item.propostaId}`}
            className="btn btn-icon btn-outline-success btn-circle btn-sm mr-2"
          >
            <i className="fas fa-search-plus"></i>
          </Link>
          {/* <a
            href="#"
            className="btn btn-icon btn-outline-success btn-circle btn-sm mr-2"
          >
            <i className="fas fa-file-invoice"></i>
          </a> */}
        </td>
      </tr>
    ));
    listItens?.unshift(
      <thead>
        <tr className="bg-gray-100 text-left">
          <th style={{ minWidth: '120px' }}>Data da solicitação</th>
          <th style={{ minWidth: '130px' }}>Valor da Viagem</th>
          <th style={{ minWidth: '130px' }}>Parcelamento</th>
          <th style={{ minWidth: '120px' }}>Valor da Parcela</th>
          <th style={{ minWidth: '110px' }}>Status</th>
          <th style={{ minWidth: '110px' }}></th>
        </tr>
      </thead>,
    );
    return (
      <table className="table table-head-custom table-head-bg table-vertical-center table-borderless">
        {listItens}
      </table>
    );
  }

  return (
    <>
      <Container>
        <Overlay active={loading}>
          <Card>
            <div className="card-header border-0 py-5">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Minhas Viagens
                </span>
                <span className="text-muted mt-3 font-weight-bold font-size-sm">
                  {userData.data.nome.split(' ')[0]}, confira nessa tela o
                  resumos de seus pedidos com a VoePay
                </span>
              </h3>
              <div className="card-toolbar">
                <a
                  href="https://voepay.com.br"
                  target="_blank"
                  rel="noreferrer"
                  className="btn btn-success font-weight-bolder font-size-sm"
                >
                  <i className="fas fa-plane-departure"></i>Quero encontrar um
                  Parceiro
                </a>
              </div>
            </div>
            <div className="card-body pt-0 pb-3">
              <div className="table-responsive">
                <ListPropostas />
              </div>
            </div>
          </Card>
        </Overlay>
      </Container>
    </>
  );
}
