import api from './api';

export const getUsuario = async (usuarioId) => {
  const { data } = await api.get(`/Usuario/GetUsuario?usuarioId=${usuarioId}`);
  return data;
};

export const atualizarUsuario = async (req) => {
  const { data } = await api.post('/Usuario/AtualizarUsuario', req);
  return data;
};
