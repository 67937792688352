import api from './api';

export const listarMovimentos = async (numeroCCB) => {
    try {
        const { data } = await api.post(
            `financeiro/ListarMovimentos?numeroCCB=${numeroCCB}`,
        );
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};

export const obterURLBoleto = async (nCodTitulo) => {
    try {
        const { data } = await api.post(
            `financeiro/ObterURLBoleto?nCodTitulo=${nCodTitulo}`,
        );
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};
export const verificarParcelasEmAtraso = async (cpf) => {
    try {
        const { data } = await api.post(
            `financeiro/VerificarParcelasEmAtraso?cpf=${cpf}`,
        );
        return data;
    } catch (error) {
        console.log(error);
        return error;
    }
};