/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

import { enviarContato } from '../../webReferences/contactService';

import SpinnerLoading from '../../components/SpinnerLogin';

import Header from './Header';
import Footer from './Footer';

import './styles.scss';
import ModalSuccess from './ModalSuccess';
import history from '../../history';

export default function Contato() {
  const [nome, setNome] = useState(null);
  const [email, setEmail] = useState(null);
  const [telefoneCelular, setTelefoneCelular] = useState(null);
  const [mensagem, setMensagem] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [emailInvalido, setEmailInvalido] = useState(false);

  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  const [showComponent, setShowComponent] = useState(false);

  const modalToggle = () => setModalVisible(!modalVisible);

  function handleChange(e) {
    const { id, value } = e.target;
    setEmailInvalido(false);

    switch (id) {
      case 'nome':
        setErrors({ ...errors, nome: 'Digite seu nome' });
        setNome(value);
        break;
      case 'email':
        setErrors({ ...errors, email: 'Digite seu e-mail' });
        setEmail(value);
        break;
      case 'telefoneCelular':
        setErrors({ ...errors, telefoneCelular: 'Digite seu telefone' });
        setTelefoneCelular(value);
        break;
      case 'mensagem':
        setErrors({ ...errors, mensagem: 'Digite a mensagem' });
        setMensagem(value);
        break;
      default:
    }
  }

  const setFieldTouched = (e) => {
    const { id } = e.target;

    switch (id) {
      case 'nome':
        setTouched({ ...touched, nome: true });
        break;
      case 'email':
        setTouched({ ...touched, email: true });
        break;
      case 'telefoneCelular':
        setTouched({ ...touched, telefoneCelular: true });
        break;
      case 'mensagem':
        setTouched({ ...touched, mensagem: true });
        break;
      default:
    }
  };

  async function handleValidate(e) {
    e.preventDefault();

    if (!nome && !email && !mensagem) {
      setTouched({ ...touched, nome: true, email: true, mensagem: true });
      setErrors({
        ...errors,
        nome: 'Digite seu nome',
        email: 'Digite seu e-mail',
        mensagem: 'Digite sua mensagem',
      });

      return;
    }

    if (!nome) {
      setTouched({ ...touched, nome: true });
      setErrors({ ...errors, nome: 'Digite seu nome' });
    } else if (!email) {
      setTouched({ ...touched, email: true });
      setErrors({ ...errors, email: 'Digite seu e-mail' });
    } else if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      setTouched({ ...touched, email: true });
      setErrors({ ...errors, email: 'E-mail inválido' });
      setEmailInvalido(true);
    } else if (!mensagem) {
      setTouched({ ...touched, mensagem: true });
      setErrors({ ...errors, mensagem: 'Digite sua mensagem' });
    } else {
      setLoading(true);
      const data = await enviarContato({
        nome,
        email,
        telefoneCelular,
        mensagem,
      }).catch(() => setLoading(false));

      if (data?.sucesso) {
        setModalVisible(true);
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    const plugins = document.createElement('script');
    const theme = document.createElement('script');

    plugins.src = '/assets/js/plugins.js';
    theme.src = '/assets/js/theme.js';

    async function loadStyles() {
      const promiseCss = () =>
        new Promise((resolve) => {
          resolve(import('../../assets/css/style.login.css'));
        });

      const promiseScripts = () =>
        new Promise((resolve) => {
          resolve(
            document.body.appendChild(plugins),
            document.body.appendChild(theme),
          );
        });

      const promises = [promiseCss, promiseScripts];

      for (let promise of promises) {
        await promise();
      }
    }

    loadStyles().then(() => {
      setShowComponent(true);
    });
  }, []);

  return (
    showComponent && (
      <div className="login">
        <div className="content-wrapper">
          <Header />
          <ModalSuccess
            modalVisible={modalVisible}
            modalToggle={modalToggle}
            action={() => history.push('/login')}
          />

          <section className="wrapper bg-soft-primary">
            <div className="container pt-10 pb-15 pt-md-14 pb-md-10 text-center">
              <div className="row">
                <div
                  className="col-md-10 col-lg-8 col-xl-8 col-xxl-4 mx-auto mb-13"
                  data-cues="slideInDown"
                  data-group="page-title"
                >
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                  <h3 className="mb-4">
                    Tem alguma dúvida? Entre em contato com a nossa equipe que
                    vamos te ajudar.
                  </h3>

                  <form className="text-start mb-3">
                    <div className="form-label-group mb-4">
                      <input
                        type="text"
                        className={`form-control ${
                          !nome && errors.nome && touched.nome && 'required'
                        }`}
                        placeholder="Nome"
                        id="nome"
                        value={nome}
                        onChange={handleChange}
                        onFocus={setFieldTouched}
                      />
                      <label htmlFor="nome">Nome</label>
                      <small>{!nome && touched.nome && errors.nome}</small>
                    </div>

                    <div className="form-label-group mb-4 col-xl-12">
                      <input
                        type="email"
                        className={`form-control ${
                          (!email || emailInvalido) &&
                          errors.email &&
                          touched.email &&
                          'required'
                        }`}
                        id="email"
                        placeholder="email"
                        value={email}
                        onChange={handleChange}
                        onFocus={setFieldTouched}
                      />

                      <label htmlFor="email">E-mail</label>

                      <small>
                        {(!email || emailInvalido) &&
                          touched.email &&
                          errors.email}
                      </small>
                    </div>

                    <div className="form-label-group mb-4 col-xl-12">
                      <InputMask
                        className="form-control"
                        mask="(99) 99999-9999"
                        id="telefoneCelular"
                        placeholder="telefoneCelular"
                        value={telefoneCelular}
                        onChange={handleChange}
                        onFocus={setFieldTouched}
                      />

                      <label htmlFor="telefoneCelular">Telefone</label>

                      {/* <small>
                        {!telefoneCelular &&
                          touched.telefoneCelular &&
                          errors.telefoneCelular}
                      </small> */}
                    </div>

                    <div className="form-label-group mb-4 col-xl-12">
                      <textarea
                        className={`form-control ${
                          !mensagem &&
                          errors.mensagem &&
                          touched.mensagem &&
                          'required'
                        }`}
                        id="mensagem"
                        placeholder="mensagem"
                        value={mensagem}
                        onChange={handleChange}
                        onFocus={setFieldTouched}
                      />

                      <label htmlFor="mensagem">Mensagem</label>

                      <small>
                        {!mensagem && touched.mensagem && errors.mensagem}
                      </small>
                    </div>

                    <button
                      className="btn btn-primary rounded-pill btn-login w-100 mb-2"
                      onClick={(e) => handleValidate(e)}
                      disabled={loading}
                    >
                      {loading ? <SpinnerLoading /> : 'Enviar'}
                    </button>
                  </form>
                  {/* <!-- /form --> */}
                </div>
                {/* <!-- /column --> */}
              </div>
              {/* <!-- /.row --> */}
            </div>
            {/* <!-- /.container --> */}
          </section>
        </div>

        <Footer />

        <div className="progress-wrap">
          <svg
            className="progress-circle svg-content"
            width="100%"
            height="100%"
            viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
          </svg>
        </div>
      </div>
    )
  );
}
