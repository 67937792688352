import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import SpinnerLogin from '../SpinnerLogin';

export default function Overlay(props) {
  return (
    <LoadingOverlay
      {...props}
      spinner={<SpinnerLogin width={20} height={20} />}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(0, 0, 0, 0.25)',
        }),
        content: (base) => ({
          ...base,
          marginTop: props.marginTop,
        }),
      }}
    >
      {props.children}
    </LoadingOverlay>
  );
}
