import React from 'react';

export default function Container({ children }) {
  return (
    <div
      className="content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="d-flex flex-column-fluid">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
